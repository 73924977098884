import style from './Label.css';

function Label(props) {

    return (
        <div className="label">
            {props.title}
        </div>
    )
}

export default Label;