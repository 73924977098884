import Input from '../Input/Input';
import Button from '../Button/Button';

function Direct(props) {

    return (
        <>
            <Input
                placeholder={"Email Address"}
                onChange={props.handleEmailChange}
                onEnterPressed={props.handleContinueButton}
                value={props.userEmail}
                type={"email"}
            />

            <Input
                placeholder="Password"
                type="password"
                onChange={props.handlePasswordChange}
                onEnterPressed={props.handleSignInButton}
            />
            
            <Button
                label="Sign In"
                onClick={props.handleSignInButton}
                isLoading={props.isLoading}
            />

            <div className="authenticate__forgot-password" onClick={() => {props.handleForgotPasswordButton()}}>
                Forgot my password
            </div>
        </>  
    )
}


export default Direct