import Input from '../Input/Input';
import Button from '../Button/Button';

function VerificationCode(props) {

    return (
        <>
            <Input
                placeholder="Emailed Code"
                onChange={props.handleVerificationCodeChange}
                onEnterPressed={props.handleVerificationCodeButton}
            />

            <Button
                label="Submit Code"
                onClick={props.handleVerificationCodeButton}
                isLoading={props.isLoading}
            />
        </>  
    )
}

export default VerificationCode


