import './Button.css';
import LoadingRipple from "../LoadingRipple/LoadingRipple";

function Button(props) {
    return (
        <button 
            className="button"
            onClick={props.onClick}
        >
            { props.isLoading ?
                <LoadingRipple></LoadingRipple>
            : props.label}
        </button>
    );
}

export default Button;