import './LoadingRipple.css';

function LoadingRipple(props) {
  return (
    <div className="loading-ripple">
        <div className={props.display === "dark" ? "lds-ripple lds-ripple--dark" : "lds-ripple"}>
            <div>
            </div>
            <div>
            </div>
        </div>
    </div>
  );
}

export default LoadingRipple;
