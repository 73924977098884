import React, { useEffect } from 'react';
import { ReactComponent as CheckSuccess } from '../../images/svgs/checksuccess.svg';
import { ReactComponent as CheckError } from '../../images/svgs/checkerror.svg';
import Button from '../Button/Button';
import './Confirmation.css';

function getTypeModifier(className, type) {
    let newClassName;

    switch (type) {
        case "error":
            newClassName = `${className} ${className}--error`;
            break;
        default:
            newClassName = `${className} ${className}--success`;
    }   

    return newClassName
}

function Confirmation(props) {
    
    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll')
        };
    }, []);

    return (
        <div className="confirmation">
        <div className="confirmation__blur"></div>
        <div className="confirmation__container">
            <div className={getTypeModifier("confirmation__icon", props.type)}>

                {props.type === "error" ? 
                    <CheckError></CheckError> :
                    <CheckSuccess></CheckSuccess>
                }
            </div>

            <div className="confirmation__title">
                {props.title}
            </div>

            <div className="confirmation__message">
                {props.message}
            </div>

            <div className={getTypeModifier("confirmation__button", props.type)}>
                {props.buttonFunction ? 
                    <Button
                        label={props.buttonTitle} 
                        onClick={props.buttonFunction}
                        type="dark">
                    </Button> 
                : null }
            </div>
            </div>
        </div>
    );
}

export default Confirmation;
