import Input from '../Input/Input';
import Button from '../Button/Button';

function Basic(props) {

    return (
        <>
            <Input
                placeholder={"Email Address"}
                type={"email"}
                onChange={props.handleEmailChange}
                onEnterPressed={props.handleContinueButton}
                value={props.userEmail}
            />

            <Button
                label="Continue"
                onClick={props.handleContinueButton}
                isLoading={props.isLoading}
            />

            <div className="authenticate__forgot-password" onClick={() => {props.handleForgotPasswordButton()}}>
                Forgot my password
            </div>
        </>  
    )
}


export default Basic