import React from 'react';
import ReactDOM from 'react-dom/client';
import SignIn from './pages/SignIn/SignIn';
import Logout from './pages/Logout/Logout';
import Profile from './pages/Profile/Profile';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';

const router = createBrowserRouter([
    {
        path: "/",
        element: <SignIn />,
    },
    {
        path: "/setcookie",
        element: <SignIn/> // Backwards compatible with v1
    },
    {
        path: "/logout",
        element: <Logout />,
    },
    {
        path: "/signout",
        element: <Logout />,
    },
    {
        path: "/profile",
        element: <Profile />, 
    },
    {
        path: "/development",
        element: <>You are now signed in. Please visit the ezyVet site or service you require.</>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);