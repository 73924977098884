import { useEffect, useState } from 'react';
import Frame from '../Authenticate/Frame';
import VerificationCode from './VerificationCode';
import ProfileOptions from './ProfileOptions';
import Confirmation from '../../components/Confirmation/Confirmation';
import * as Constants from '../../js//Constants';
import './Profile.css';
import { 
    postAuthenticationTasks, 
    confirmAttributeUpdate,
    toggleMfaPreference, 
    updateEmailAddress,
    updateUserPassword, 
    getMfaPreference,
    getIsFederatedUser,
    getEmailAddress,
    isSignedIn
} from '../../js/Cognito';

function Profile(props) {
    const [mode, setMode] = useState("PROFILE");
    const [isFederated, setIsFederated] = useState(true);

    const [title, setTitle] = useState(Constants.PROFILE_TITLE);
    const [description, setDescription] = useState(Constants.PROFILE_DESCRIPTION);

    const [emailCode, setEmailCode] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [newEmailAddress, setNewEmailAddress] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [repeatNewPassword, setRepeatNewPassword] = useState(null);

    const [enableMfa, setEnableMfa] = useState(false);
    const [enableMfaOriginalState, setEnableMfaOriginalState] = useState(false);

    const [modal, setModal] = useState(null);
    const [isProfilePageLoading, setIsProfilePageLoading] = useState(true);

    useEffect(() => {
        init();
    }, [])
    
    async function init() {
        // Check if signed in
        if (!await isSignedIn()) {
            window.location.href = '/#state=/profile';
        }

        // Get MFA preference
        let mfaEnabled = await getMfaPreference();
        setEnableMfa(mfaEnabled)
        setEnableMfaOriginalState(mfaEnabled)

        // Get user's email address
        let emailAddress = await getEmailAddress();
        setEmailAddress(emailAddress);

        // Check if user is federated
        let isFederatedUser = await getIsFederatedUser();
        setIsFederated(isFederatedUser);

        // Set UI message
        if (isFederatedUser) {
            setErrorMessage(Constants.IDP_USER_DEFAULT)
        }

        setIsProfilePageLoading(false);
    }
 
    // Button handler
    async function handleSaveButton() {
        setIsLoading(true);
        
        // Password
        if (newPassword || repeatNewPassword) {
            if (!newPassword || !repeatNewPassword || !oldPassword) {
                setErrorMessage(Constants.PASSWORD_EMPTY_ERROR);
                setIsLoading(false);
                return false;
            }
    
            if (newPassword !== repeatNewPassword) {
                setErrorMessage(Constants.PASSWORD_MISMATCH_ERROR);
                setIsLoading(false);
                return false;
            }

            let [wasSuccessful, error] = await updateUserPassword(oldPassword, newPassword)
            if (!wasSuccessful) {
                setErrorMessage(error)
                setIsLoading(false);
                return false;
            }
        }

        // Email
        if (newEmailAddress && (newEmailAddress !== emailAddress)) {
            console.log("Email change requested: " + newEmailAddress)
            let [wasSuccessful, error] = await updateEmailAddress(newEmailAddress)
            if (!wasSuccessful) {
                setErrorMessage(error)
                setIsLoading(false);
                return false;
            }

            setMode("CONFIRM_ATTRIBUTE_WITH_CODE");
            setIsLoading(false);
            setTitle(Constants.ENTER_CODE_TITLE);
            setDescription(Constants.CHANGE_EMAIL_ADDRESS_CODE);
            return true;
        }

        // MFA
        if (enableMfa !== enableMfaOriginalState) {
            let [wasSuccessful, error] = await toggleMfaPreference(enableMfa);
            if (!wasSuccessful) {
                setErrorMessage(error)
                setIsLoading(false);
                return false;
            }
        }
        
        setErrorMessage(null);
        setIsLoading(false);

        setModal({
            title: "Profile Updated",
            message: "Your profile was updated successfully",
            type: "success",
            buttonTitle: "Okay",
            buttonFunction: function() {
                postAuthenticationTasks();
                setModal(null);
            }
        });
    }

    async function handleVerificationCodeButton() {
        setIsLoading(true);

        if (!emailCode) {
            setErrorMessage(Constants.CHANGE_EMAIL_ADDRESS_CODE_EMPTY);
            setIsLoading(false);
            return false;
        }

        let [wasSuccessful, error] = await confirmAttributeUpdate(emailCode)
        if (!wasSuccessful) {
            setErrorMessage(error)
            setIsLoading(false);
            return false;
        }

        setErrorMessage(null);
        setIsLoading(false);

        setModal({
            title: "Profile Updated",
            message: "Your profile was updated successfully",
            type: "success",
            buttonTitle: "Okay",
            buttonFunction: function() {
                postAuthenticationTasks();
                setModal(null);
            }
        });
    }

    // Input handlers
    function handleNewEmailAddressChange(event) {
        setNewEmailAddress(event.target.value);
    }

    function handleEnableMfaChange() {
        setEnableMfa(!enableMfa);
    }

    function handleOldPasswordChange(event) {
        setOldPassword(event.target.value);
    }

    function handleNewPasswordChange(event) {
        setNewPassword(event.target.value);
    }

    function handleRepeatNewPasswordChange(event) {
        setRepeatNewPassword(event.target.value);
    }

    function handleVerificationCodeChange(event) {
        setEmailCode(event.target.value);
    }

    return (
        <Frame
            title={title}
            description={description}
            errorMessage={errorMessage}
            isLoading={isProfilePageLoading}>

            { modal &&      
                <Confirmation
                    title = {modal.title}
                    message = {modal.message}
                    type = {modal.type}
                    buttonTitle = {modal.buttonTitle}
                    buttonFunction = {modal.buttonFunction}>                            
                </Confirmation>
            }

            {mode === "PROFILE" && 
                <ProfileOptions
                    isFederated={isFederated}
                    handleNewEmailAddressChange={handleNewEmailAddressChange}
                    handleOldPasswordChange={handleOldPasswordChange}
                    handleNewPasswordChange={handleNewPasswordChange}
                    handleRepeatNewPasswordChange={handleRepeatNewPasswordChange}
                    handleEnableMfaChange={handleEnableMfaChange}
                    handleSaveButton={handleSaveButton}
                    emailAddress={emailAddress}
                    isLoading={isLoading}
                    enableMfa={enableMfa}>
                </ProfileOptions>
            }

            {mode === "CONFIRM_ATTRIBUTE_WITH_CODE" && 
                <VerificationCode
                    handleVerificationCodeChange={handleVerificationCodeChange}
                    handleVerificationCodeButton={handleVerificationCodeButton}
                    isLoading={isLoading}>
                </VerificationCode>
            }
        </Frame>
    )
}

export default Profile;