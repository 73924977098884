import './Input.css';
import { ReactComponent as Locked } from '../../images/svgs/locked.svg';

// Make inputs do something when enter is pressed
function onEnterPressed(event, callback) {
    if (event.key === 'Enter' && callback) {
        callback();
    }
}

function Input(props) {
    return (
        <div className="input">
            <input 
                className="input__field"
                placeholder={props.placeholder}
                pattern={props.pattern}
                disabled={props.isLocked ? true : props.disabled}
                onChange={props.onChange}
                type={props.type}
                value={props.value}
                onKeyPress={event => onEnterPressed(event, props.onEnterPressed)}
            />

            {props.isLocked &&
                <div className="input__locked">
                    <Locked></Locked>
                </div>
            }
        </div>
    );
}

export default Input;