import './Profile.css';
import { useState } from 'react';
import LoadingRipple from "../../components/LoadingRipple/LoadingRipple";
import Profile from '../../components/Profile/Profile';

function ProfilePage() {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            {!isLoading ?
                <div className="profile">
                    <div className="profile__left-container">
                        <div tabIndex="-1" 
                            loading="lazy" 
                            className="profile__iframe" 
                            src="https://ezyvet.com/">
                        </div>
                    </div>
                    
                    <div className="profile__right-container">
                        <Profile>
                        </Profile>
                    </div>
                </div> 
            : 
                <div className="profile">
                    <div className="profile__loading">
                        <LoadingRipple 
                            display={"dark"}>
                        </LoadingRipple>
                    </div>
                </div>
            }
        </>
    );
}

export default ProfilePage;
