import style from './Authenticate.css'
import Input from '../Input/Input';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';

function ChangePassword(props) {

    return (
        <>
            <Input
                placeholder="New Password"
                type="password"
                onChange={props.handleNewPasswordChange}
                onEnterPressed={props.handleChallangeButton}
            />

            <Input
                placeholder="Repeat Password"
                type="password"
                onChange={props.handleRepeatNewPasswordChange}
                onEnterPressed={props.handleChallangeButton}
            />

            <Checkbox
                label="Setup MFA"
                onChange={props.handleEnableMfaChange}
                checked={props.enableMfa}
                isLoading={props.isLoading}
            />  

            <Button
                label="Set Password"
                onClick={props.handleChallangeButton}
                isLoading={props.isLoading}
            />
        </>  
    )
}


export default ChangePassword