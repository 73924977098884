import { getEnvironmentUrl } from './Helper';

async function callApi(method, path, header, body) {
    var domain = getEnvironmentUrl();
    var response;

    try {
        response = await fetch(`${domain}${path}/`, {
            method: method || "GET",
            headers: header || {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        response = await response.json();
    } catch (error) {
        console.error(error);
        return false;
    }

    return response;
}

export {
    callApi
}