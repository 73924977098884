import Input from '../Input/Input';
import Button from '../Button/Button';

function ResetPassword(props) {

    return (
        <>
            <Input
                placeholder="Code"
                onChange={props.handleResetCodeChange}
                onEnterPressed={props.handleResetPasswordButton}
            />

            <Input
                placeholder="New Password"
                type="password"
                onChange={props.handleResetPasswordChange}
                onEnterPressed={props.handleResetPasswordButton}
            />

            <Button
                label="Set Password"
                onClick={props.handleResetPasswordButton}
                isLoading={props.isLoading}
            />
        </>  
    )
}


export default ResetPassword