import { useEffect } from 'react';
import { signOutCustom } from '../../js/Cognito';
import { isLocalHost } from '../../js/Helper';
import Bye from '../../images/bye.webp';
import './Logout.css';

function Logout() {

    useEffect(() => {
        init();
    }, []);

    async function init () {
        if (isLocalHost()) {
            window.LOG_LEVEL = 'DEBUG';
        } else {
            window.LOG_LEVEL = 'INFO';
        }
        await signOutCustom();
    }

    return (
        <div className="logout">
            <div className="logout__container">
                <div className="logout__title">
                    Goodbye!
                </div>

                <div className="logout__description">
                    Please wait while you will are signed out of ezyVet
                </div>

                <div className="logout__image">
                    <img alt="Animals" src={Bye}></img>
                </div>
            </div>
        </div>
    );
}

export default Logout;
