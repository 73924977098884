import './Banner.css';
import { getEnvironmentName } from '../../js/Helper';
import { useState, useEffect } from 'react';
import { ReactComponent as Alert } from '../../images/svgs/alert.svg';
import { ReactComponent as Close } from '../../images/svgs/close.svg';


function getDefaultMessage(override = null) {
    let id, header, body, active;

    switch (override || getEnvironmentName()) {
        case "production":
            break;
        case "trial":
            id = "trial";
            active = true;
            header = "Trial";
            body = "This page is intended for trial purposes only";
            break;
        case "staging":
            id = "staging"
            active = true;
            header = "Staging";
            body = "This page is intended for staging purposes only";
            break;
        default:
            id = "development";
            active = true;
            header = "Development";
            body = "You are running a development version of ezyVet Login";
    }

    if (id && header && body) {
        return {
            'id': id,
            'active': active,
            'header': header,
            'body': body
        };
    }
}

function Banner(props) {
    const [messages, setMessages] = useState([getDefaultMessage()]);

    // Removes an alert message when a user closes it
    function removeMessage(id) {
        setMessages(messages.filter((message) => {
            return message && message["id"] !== id;
        }));
    }

    function getRemoteMessages() {
        callAPI("/get-configuration", {})
        .then((result) => {
            if (result.messages) {
                setMessages([...messages, ...result.messages]);
            }
        });
    }

    // Call API to return site messages
    useEffect(() => {
        //getRemoteMessages();
    }, []);

    return (
        <div className="banner">
                {messages && messages.map(message => (message && message.active) ?
                    <div key={message["id"]} className="banner__container">
                        <div className="banner__icon banner__icon--alert">
                            <Alert></Alert>
                        </div>

                        <div className="banner__message-container">
                            <div className="banner__message-header">{message["header"]}</div>
                            <div className="banner__message-body">{message["body"]}</div>
                        </div>

                        <div onClick={() => removeMessage(message["id"])} className="banner__close">
                            <Close></Close>
                        </div>
                    </div> 
                    : null
                )}
        </div>
    );
}

export default Banner;