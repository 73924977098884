import './SignIn.css';
import { useState, useEffect } from 'react';
import { initiateSession } from '../../js/Cognito';
import LoadingRipple from "../../components/LoadingRipple/LoadingRipple";
import Authenticate from '../../components/Authenticate/Authenticate';
import Banner from '../../components/Banner/Banner';

function SignIn() {
    const [shouldSignIn, setShouldSignIn] = useState(false);

    useEffect(() => {
        initiateSession(setShouldSignIn);
    }, []);

    return (
        <>
            {shouldSignIn ?
                <div className="signin">
                    <div className="signin__left-container">
                        <div tabIndex="-1" 
                            loading="lazy" 
                            className="signin__iframe" 
                            src="https://ezyvet.com/">
                        </div>
                    </div>
                    
                    <div className="signin__right-container">
                        <Authenticate>
                        </Authenticate>
                    </div>
                    <Banner></Banner>
                </div> 
            : 
                <div className="signin">
                    <div className="signin__loading">
                        <LoadingRipple 
                            display={"dark"}>
                        </LoadingRipple>
                    </div>
                </div>
            }
        </>
    );
}

export default SignIn;
