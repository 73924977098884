import { 
    setUpMfa,
    verifyMfaSetup,
    postAuthenticationTasks,
    authenticateAgainstIdp,
    handleConfirmResetPassword,
    authenticateAgainstPool, 
    getUserConfiguration, 
    completeChallange,
    forgotPassword,
    getUserEmail
} from '../../js/Cognito';
import { useEffect, useState } from 'react';
import Frame from './Frame';
import Basic from './Basic';
import Direct from './Direct';
import MfaCode from './MfaCode';
import SetupMfa from './SetupMfa';
import ChangePassword from './ChangePassword';
import ResetPassword from './ResetPassword';
import * as Constants from '../../js/Constants';
import './Authenticate.css';

function Authenticate(props) {
    const [mfaSetupCode, setMfaSetupCode] = useState(null);
    const [mfaSeed, setMfaSeed] = useState(null);
    const [mfaCode, setMfaCode] = useState(null);

    const [userEmail, setUserEmail] = useState("");
    const [userEmailIsValid, setUserEmailIsValid] = useState(true);
    const [userPassword, setUserPassword] = useState(null);
    const [userNewPassword, setUserNewPassword] = useState(null);
    const [userRepeatNewPassword, setUserRepeatNewPassword] = useState(null);
    const [userConfiguration, setUserConfiguration] = useState(null);
    const [enableMfa, setEnableMfa] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [signInMode, setSignInMode] = useState("BASIC");
    const [title, setTitle] = useState(Constants.AUTHENTICATE_DEFAULT_TITLE);
    const [description, setDescription] = useState(Constants.AUTHENTICATE_DEFAULT_WELCOME);
    const [errorMessage, setErrorMessage] = useState(null);

    const [resetPassword, setResetPassword] = useState(null);
    const [resetCode, setResetCode] = useState(null);

    const [modal, setModal] = useState(null);

    // Pre-populate the user's email
    useEffect(() => {
        var userEmail = getUserEmail();
        if (userEmail) {
            setUserEmail(userEmail);
        }
    }, []);

    useEffect(() => {
        if (signInMode === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
            setTitle(Constants.AUTHENTICATE_DEFAULT_TITLE)
            setDescription(Constants.PASSWORD_SET_MESSAGE);
        } else if (signInMode === "SETUP_MFA") {
            setTitle(Constants.SETUP_MFA_TITLE)
            setDescription(Constants.SETUP_MFA_MESSAGE);
        } else if (signInMode === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
            setTitle(Constants.MFA_CODE_NEEDED_TITLE);
            setDescription(Constants.MFA_CODE_NEEDED_MESSAGE);
        } else if (signInMode === "RESET_PASSWORD") {
            setTitle(Constants.RESET_PASSWORD_TITLE);
            setDescription(Constants.RESET_PASSWORD_DESCRIPTION);
        } else {
            setTitle(Constants.AUTHENTICATE_DEFAULT_TITLE)
            setDescription(Constants.AUTHENTICATE_DEFAULT_WELCOME)
        }
    }, [signInMode])

    useEffect(() => {
        setUserConfiguration(null);
        setSignInMode("BASIC"); 
    }, [userEmail]);

    useEffect(() => {
        selectSignInFlow();
        setErrorMessage(null);
    }, [userConfiguration]);

    useEffect(() => {
        // Workaround for v6 Amplify now allowing password change + MFA setup in the same flow
        setMfaSetupFlag();
    })
    
    function setMfaSetupFlag() {
        window.WANTS_MFA = enableMfa;
    }

    function removeMfaSetupFlag() {
        window.WANTS_MFA = null;
    }

    function selectSignInFlow() {
        if (!userEmail || !userEmailIsValid) {
            return false;
        }
        
        if (userConfiguration?.identityProvider) {
            authenticateAgainstIdp(userConfiguration);
        } else {
            setSignInMode("DIRECT");
            setIsLoading(false);
        }
    }

    // Button handlers

    async function handleResetPasswordButton() {
        setIsLoading(true);

        if (!userEmail || !userEmailIsValid) {
            setErrorMessage(Constants.EMAIL_EMPTY_ERROR);
            setIsLoading(false);
            return false;
        }

        if (!resetCode || !resetPassword) {
            setErrorMessage(Constants.PASSWORD_EMPTY_ERROR);
            setIsLoading(false);
            return false;
        }

        let [wasSuccessful, error] = await handleConfirmResetPassword(userEmail, resetCode, resetPassword)
        if (!wasSuccessful) {
            setErrorMessage(error)
            setIsLoading(false);
            return
        }
        
        removeMfaSetupFlag();
        setErrorMessage(null);
        setIsLoading(false);
        window.location.replace("/"); // User now needs to sign in
    }

    async function handleMfaButton() {
        setIsLoading(true);

        if (!mfaCode) {
            setErrorMessage(Constants.MFA_EMPTY_CODE);
            setIsLoading(false);
            return;
        }

        let [wasSuccessful, error] = await completeChallange(mfaCode)
        if (!wasSuccessful) {
            setErrorMessage(error)
            setIsLoading(false);
            return;
        }

        setErrorMessage(null);
        setIsLoading(false);
    }

    async function handleForgotPasswordButton() {
        setIsLoading(true);
        if (!userEmail || !userEmailIsValid) {
            setErrorMessage(Constants.EMAIL_EMPTY_ERROR);
            setIsLoading(false);
            return false;
        }
        
        let [wasSuccessful, error] = await forgotPassword(userEmail);
        if (!wasSuccessful) {
            setErrorMessage(error);
            setIsLoading(false);
            return;
        }

        setSignInMode("RESET_PASSWORD");
        setErrorMessage(null);
        setIsLoading(false);
    }

    async function handleContinueButton() {
        setIsLoading(true);

        if (!userEmail || !userEmailIsValid) {
            setErrorMessage(Constants.EMAIL_EMPTY_ERROR);
            setIsLoading(false);
            return false;
        }

        const userConfiguration = await getUserConfiguration(userEmail);
        if (!userConfiguration) {
            setErrorMessage(Constants.INVALID_CONFIG_ERROR);
            setIsLoading(false);
            return false;
        } 
        
        setUserConfiguration(userConfiguration);
        setIsLoading(false);
    }

    async function handleSignInButton() {
        setIsLoading(true);

        if (!userEmail || !userEmailIsValid) {
            setErrorMessage(Constants.EMAIL_EMPTY_ERROR);
            setIsLoading(false);
            return false;
        }

        if (!userPassword) {
            setErrorMessage(Constants.PASSWORD_EMPTY_ERROR);
            setIsLoading(false);
            return false;
        }

        let [wasSuccessful, error] = await authenticateAgainstPool(userEmail, userPassword, userConfiguration, setSignInMode)
        if (!wasSuccessful) {
            setErrorMessage(error)
            setIsLoading(false);
            return false;
        }

        setErrorMessage(null);
        setIsLoading(false);
    }

    async function handleChallangeButton() {
        setIsLoading(true);

        if (!userNewPassword || !userRepeatNewPassword) {
            setErrorMessage(Constants.PASSWORD_EMPTY_ERROR);
            setIsLoading(false);
            return false;
        }

        if (userNewPassword !== userRepeatNewPassword) {
            setErrorMessage(Constants.PASSWORD_MISMATCH_ERROR);
            setIsLoading(false);
            return false;
        }

        let [wasSuccessful, error] = await completeChallange(userNewPassword)
        if (!wasSuccessful) {
            setErrorMessage(error)
            setIsLoading(false);
            return;
        }

        // MFA setup can only occur after the new password has been set
        if (enableMfa) {
            setSignInMode("SETUP_MFA")
            setMfaSeed(await setUpMfa())
        }

        setErrorMessage(null);
        setIsLoading(false);
    }

    async function handleSetupMfaButton() {
        setIsLoading(true);

        let [wasSuccessful, error] = await verifyMfaSetup(mfaSetupCode);
        if (!wasSuccessful) {
            setErrorMessage(error);
            setIsLoading(false);
            return;
        }

        // Remove MFA setup flags
        removeMfaSetupFlag();
        setErrorMessage(null);
        setIsLoading(false);
        postAuthenticationTasks();
    }

    // Input handlers

    function handleResetPasswordChange(event) {
        setResetPassword(event.target.value);
    }

    function handleResetCodeChange(event) {
        setResetCode(event.target.value);
    }

    function handleMfaCodeChange(event) {
        setMfaCode(event.target.value);
    }

    function handleMfaSetupCodeChange(event) {
        setMfaSetupCode(event.target.value);
    }

    function handleEnableMfaChange() {
        setEnableMfa(!enableMfa);
    }

    function handleNewPasswordChange(event) {
        setUserNewPassword(event.target.value)
    }

    function handleRepeatNewPasswordChange(event) {
        setUserRepeatNewPassword(event.target.value)
    }

    function handleEmailChange(event) {
        setUserEmail(event.target.value.toLowerCase());
        setUserEmailIsValid(!event.target.validity.patternMismatch);
    }

    function handlePasswordChange(event) {
        setUserPassword(event.target.value);
    }

    return (
        <Frame
            title={title}
            description={description}
            errorMessage={errorMessage}>

            {signInMode === "BASIC" &&
                <Basic
                    handleForgotPasswordButton={handleForgotPasswordButton}
                    handleContinueButton={handleContinueButton}
                    handleEmailChange={handleEmailChange}
                    isLoading={isLoading}
                    userEmail={userEmail}>
                </Basic>
            }

            {signInMode === "DIRECT" &&
                <Direct 
                    handleForgotPasswordButton={handleForgotPasswordButton}
                    handleContinueButton={handleContinueButton}
                    handlePasswordChange={handlePasswordChange}
                    handleSignInButton={handleSignInButton}
                    handleEmailChange={handleEmailChange}
                    isLoading={isLoading}
                    userEmail={userEmail}>
                </Direct>
            }

            {signInMode === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED" &&
                <ChangePassword 
                    handleRepeatNewPasswordChange={handleRepeatNewPasswordChange}
                    handleNewPasswordChange={handleNewPasswordChange}
                    handleEnableMfaChange={handleEnableMfaChange}
                    handleChallangeButton={handleChallangeButton}
                    handlePasswordChange={handlePasswordChange}
                    isLoading={isLoading}
                    enableMfa={enableMfa}>
                </ChangePassword>
            }

            {signInMode === "SETUP_MFA" &&
                <SetupMfa
                    handleMfaSetupCodeChange={handleMfaSetupCodeChange}
                    handleSetupMfaButton={handleSetupMfaButton}
                    isLoading={isLoading}
                    mfaSeed={mfaSeed}>
                </SetupMfa>
            }

            {signInMode === "CONFIRM_SIGN_IN_WITH_TOTP_CODE" && 
                <MfaCode
                    handleMfaCodeChange={handleMfaCodeChange}
                    handleMfaButton={handleMfaButton}
                    isLoading={isLoading}>
                </MfaCode>
            }

            {signInMode === "RESET_PASSWORD" &&
                <ResetPassword
                    handleResetPasswordChange={handleResetPasswordChange}
                    handleResetPasswordButton={handleResetPasswordButton}
                    handleResetCodeChange={handleResetCodeChange}
                    isLoading={isLoading}>
                </ResetPassword>
            }
        </Frame>
    )
}

export default Authenticate;