import './Checkbox.css';

function Checkbox(props) {
  return (
    <div className="checkbox">
        <input 
            id="checkboxplus" 
            type="checkbox" 
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            tabIndex={props.tabIndex}
            disabled={props.isLocked}>
        </input>

        <label htmlFor="checkboxplus">
        </label>

        <div className="checkbox__label">
            {props.label}
        </div>
    </div>
  );
}

export default Checkbox;

