import Input from '../Input/Input';
import Button from '../Button/Button';

function MfaCode(props) {

    return (
        <>
            <Input
                placeholder="MFA Code"
                onChange={props.handleMfaCodeChange}
                onEnterPressed={props.handleMfaButton}
            />

            <Button
                label="Confirm Code"
                onClick={props.handleMfaButton}
                isLoading={props.isLoading}
            />
        </>  
    )
}

export default MfaCode


