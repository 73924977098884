export const PROD_EZYVET_LOGIN_URL = 'login.ezyvet.com';
export const TRIAL_EZYVET_LOGIN_URL = 'login.trial.ezyvet.com';
export const STAGING_EZYVET_LOGIN_URL = 'login.stg.ezyvet.com';

export const PROD_EZYVET_LOGIN_COOKIE_DOMAIN = '.ezyvet.com';
export const TRIAL_EZYVET_LOGIN_COOKIE_DOMAIN = '.trial.ezyvet.com';
export const STAGING_EZYVET_LOGIN_COOKIE_DOMAIN = '.stg.ezyvet.com';

export const PROD_COGNITO_POOL = 'us-west-2_QNwcJvwo0';
export const TRIAL_COGNITO_POOL = 'us-west-2_AAgP5cLD7';
export const STAGING_COGNITO_POOL = 'us-west-2_DWCEySyNl';

export const DEV_COGNITO_SIGN_IN_URLS = ["https://localhost:8080/setcookie"];
export const DEV_COGNITO_SIGN_OUT_URLS = ["https://localhost:8080"];
export const PROD_COGNITO_SIGN_IN_URLS = ["https://login.ezyvet.com/setcookie"];
export const TRIAL_COGNITO_SIGN_IN_URLS = ["https://login.trial.ezyvet.com/setcookie"];
export const STAGING_COGNITO_SIGN_IN_URLS = ["https://login.stg.ezyvet.com/setcookie"];
export const PROD_COGNITO_SIGN_OUT_URLS = ["https://login.ezyvet.com"];
export const TRIAL_COGNITO_SIGN_OUT_URLS = ["https://login.trial.ezyvet.com"];
export const STAGING_COGNITO_SIGN_OUT_URLS = ["https://login.stg.ezyvet.com"];

export const PROD_COGNITO_ENDPOINT = 'idp.ezyvet.com';
export const TRIAL_COGNITO_ENDPOINT = 'idp-trial-ezyvet.auth.us-west-2.amazoncognito.com';
export const STAGING_COGNITO_ENDPOINT = 'idp-stg-ezyvet.auth.us-west-2.amazoncognito.com';

export const PROD_EZYVET_LOGIN_COOKIE_ENV = 'prod';
export const TRIAL_EZYVET_LOGIN_COOKIE_ENV = 'trial';
export const STAGING_EZYVET_LOGIN_COOKIE_ENV = 'staging';

export const COOKIE_NAME_EMAIL = 'email';
export const COGNITO_REGION = 'us-west-2';
export const EZYVET_LOGIN_USER_IDP_PATH = '/get-sso';

export const EZYVET_LOGIN_COOKIE_PROVIDER = 'provider';
export const EZYVET_LOGIN_COOKIE_CLIENT = 'client';
export const EZYVET_LOGIN_COOKIE_ID = 'id';
export const EZYVET_LOGIN_COOKIE_EMAIL = 'email';
export const EZYVET_LOGIN_COOKIE_PREFIX = 'ezyvet';

export const EZYVET_APP_DOMAIN = "https://app.ezyvet.com";
export const EZYVET_DEV_DOMAIN = "https://localhost:8080/development";

export const EZYVET_DOMAIN = "@ezyvet.com";
export const IDEXX_DOMAIN = "@idexx.com";

export const PROD_EZYVET_LOGIN_DEFAULT_CLIENT = "32kv6vks72sb5p6a1ulu6eicd3";
export const TRIAL_EZYVET_LOGIN_DEFAULT_CLIENT = "fdc59mhi4vn7a19fr208vdc56";
export const STAGING_EZYVET_LOGIN_DEFAULT_CLIENT = "1fdl8c0k4ql7eslo8ni32m1bbj";

export const AUTHENTICATE_DEFAULT_TITLE = "Sign In!"
export const AUTHENTICATE_DEFAULT_WELCOME = "Sign in below to access your ezyVet services"
export const PROFILE_TITLE = "Your Profile"
export const PROFILE_DESCRIPTION = "Review and update your ezyVet account details"
export const RESET_PASSWORD_TITLE = "Enter Recovery Code"
export const ENTER_CODE_TITLE = "Enter Verification Code"
export const RESET_PASSWORD_DESCRIPTION = "A verification code has been sent to your email address. Please enter it now."

export const SLOW_DOWN = "You have exceeded our attempt limit. Please try again later"

export const ALREADY_SIGNED_IN_ERROR = "You are already signed in. Please refresh the page."
export const USERNAME_PASSWORD_ERROR = "Your username or password is incorrect. Please try again"
export const FAILED_CHALLENGE_ERROR = "Error. Please try again"
export const PASSWORD_EMPTY_ERROR = "You must enter a password. Please try again"
export const PASSWORD_MISMATCH_ERROR = "Your new passwords do not match. Please try again"
export const EMAIL_EMPTY_ERROR = "You must enter a valid email address. Please try again"
export const INVALID_CONFIG_ERROR = "An issue occured when loading your profile. Please try again"
export const NOT_AUTHENTICATED_ERROR = "You are not signed in. Please refresh the page to sign in."

export const PASSWORD_CANNOT_RESET = "We cannot reset your password as your email address is not verified or not registered. Please contact ezyVet Support."
export const PASSWORD_NOT_COMPLEX = "Your password does not meet our complexity requirements. Please try again"
export const PASSWORD_TOO_SHORT = "Your password is too short. Please try again"
export const PASSWORD_TOO_LONG = "Your password is too long. Please try again"
export const PASSWORD_SET_MESSAGE = "Please enter a new password for your account"
export const PASSWORD_INCORRECT = "Your password is incorrect. Please try again."
export const PASSWORD_FORGOTTEN = "A verification code has been sent to your email. Please enter it now."

export const RESET_USERNAME_EMPTY = "You have not entered a username. Please try again."
export const RESET_CODE_EMPTY = "You have not entered a reset code. Please try again."
export const RESET_CODE_EXPIRED = "The verification code you entered has expired. Please try again"
export const RESET_CODE_INVALID = "The verification code you entered is incorrect. Please try again"
export const SIGN_UP_MAIL_TO = "mailto:support@ezyvet.com?subject=ezyVet Account Request"
export const IDP_USER_NOT_AUTHORISED = "You are not authorised to make this change. Your account is managed by your organisation."
export const IDP_USER_DEFAULT = "Your account settings are managed by your organisation."

export const MFA_EMPTY_CODE = "Please enter the ezyVet MFA code from your authenticator app like Authy or Google Authenticator."
export const MFA_TOO_SHORT = "MFA code too short. Please try again."
export const MFA_TOO_LONG = "MFA code too long. Please try again."
export const MFA_WRONG = "The MFA code you entered is incorrect. Please try again."
export const MFA_CODE_NEEDED_TITLE = "Enter MFA Code"
export const MFA_CODE_NEEDED_MESSAGE = "Please enter your ezyVet MFA code from your MFA app like Authy or Google Authenticator"
export const SETUP_MFA_TITLE = "Setup MFA"
export const SETUP_MFA_MESSAGE = "Scan the QR code below with a MFA app like Authy or Google Authenticator."

export const CHANGE_EMAIL_ADDRESS_CODE_WRONG = "You have entered an invalid verification code. Please try again."
export const CHANGE_EMAIL_ADDRESS_CODE_EMPTY = "You have not entered a verification code. Please try again."
export const CHANGE_EMAIL_ADDRESS_CODE = "A verification code has been sent to your new email address. Please enter it now."
export const CHANGE_EMAIL_ADDRESS_ERROR = "Your email address could not be changed. Please try again."
export const CHANGE_EMAIL_ADDRESS_INVALID = "You have entered an invalid email address. Please try again."
export const CHANGE_EMAIL_ADDRESS_IN_USE = "You cannot use the email address provided. Please contact ezyVet support."

export const OAUTH_SCOPES = [
    'phone',
    'email',
    'profile',
    'openid',
    'aws.cognito.signin.user.admin'
]
