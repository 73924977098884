import { Amplify } from 'aws-amplify';
import { ConsoleLogger as Logger, Hub } from 'aws-amplify/utils';
import { 
    signIn, 
    signOut, 
    signInWithRedirect, 
    fetchAuthSession, 
    confirmSignIn,
    updatePassword,
    resetPassword,
    setUpTOTP,
    getCurrentUser,
    verifyTOTPSetup,
    fetchMFAPreference,
    updateMFAPreference,
    confirmUserAttribute,
    confirmResetPassword,
    updateUserAttribute,
    fetchUserAttributes,
} from 'aws-amplify/auth';
import { callApi } from './Api';
import { 
    setCookie,
    getCookie, 
    getFromJwt,
    isLocalHost,
    getHashState,
    getUriParameter,
    removeHashState,
    getCookieDomain,
    printCognitoError,
    getCookieModifier,
    getCognitoUserPool, 
    getCognitoEndpoint,
    getCognitoDefaultClient,
    getCognitoRedirectSignInEndpoint,
    getCognitoRedirectSignOutEndpoint
} from './Helper';
import * as Constants from './Constants';

var storedHashState;
var logger;

// Set logging levels depending on environment
logger = new Logger('ezyVet Login');
if (isLocalHost()) {
    window.LOG_LEVEL = 'DEBUG';
} else {
    window.LOG_LEVEL = 'INFO';
}

await initiateAmplify();
initiateListener();

function getUserEmail() {
    var userEmail = getCookie(`${Constants.EZYVET_LOGIN_COOKIE_PREFIX}_${getCookieModifier()}_${Constants.EZYVET_LOGIN_COOKIE_EMAIL}`);
    if (!userEmail) {
        logger.warn("User: Missing user email");
        return false;
    }
    
    try {
        userEmail = atob(userEmail);
        logger.warn(`User: Found user email ${userEmail}`);
    } catch (error) {
        logger.error("User: Failed to decode email address cookie");
        return false;
    }

    return userEmail;
}

function getDomainHintEmail() {
    var domainHintEmail = getUriParameter('domain');
    if (!domainHintEmail) {
        logger.warn(`Hint: No domain hint provided`);
        return false;
    }

    logger.warn(`Hint: Found domain hint ${domainHintEmail}`);
    domainHintEmail = 'placeholder@' + domainHintEmail;

    return domainHintEmail;
}

async function getIdpConfiguration() {
    logger.debug("idP Configuration: Fetching user configuration");

    var userEmail = getUserEmail();
    if (!userEmail) {
        userEmail = getDomainHintEmail();
    }

    if (!userEmail) {
        logger.warn("idP Configuration: User email and domain hint not available, skipping bootstrap");
        return false;
    }

    var userIdp = await getUserConfiguration(userEmail);
    if (!userIdp) {
        logger.warn("idP Configuration: Failed to get valid user idP configuration, skipping bootstrap");
        return false;
    }
    
    return userIdp;
}

async function initiateAmplify(userIdp) {
    logger.debug("Bootstrap: Bootstrapping Amplify");

    if (!userIdp) {
        userIdp = await getIdpConfiguration();
    }

    var amplifyConfiguration = getAmplifyConfiguration(userIdp);
    if (!amplifyConfiguration) {
        return false;
    }

    try {
        logger.debug(`Amplify: Generated configuration: ${JSON.stringify(amplifyConfiguration)}`)
        Amplify.configure(amplifyConfiguration);
    } catch (error) {
        logger.error(error);
        return false;
    }
    
    logger.debug("Bootstrap: Bootstrapping Amplify complete");
    return userIdp;
}

function initiateListener() {
    logger.debug(`Hub: Listener starting`);
    Hub.listen('auth', ({ payload: { event, data } }) => {
        logger.warn(`Hub: Event ${event}`);

        switch (event) {
            case 'configured':
                logger.debug('Hub: Amplify auth module configured successfully');
                break;
            case 'signIn_failure':
                logger.error('Hub: User sign in failed');
                break;
            case 'signUp':
                logger.debug('Hub: User signed up');
                break;
            case 'signUp_failure':
                logger.error('Hub: User sign up failed');
                break;
            case 'confirmSignUp':
                logger.debug('Hub: User confirmation successful');
                break;
            case 'completeNewPassword_failure':
                logger.error('Hub: User did not complete new password flow');
                break;
            case 'autoSignIn':
                logger.debug('Hub: Auto sign in successful');
                break;
            case 'autoSignIn_failure':
                logger.error('Hub: Auto sign in failed');
                break;
            case 'forgotPassword':
                logger.debug('Hub: Password recovery initiated');
                break;
            case 'forgotPassword_failure':
                logger.error('Hub: Password recovery failed');
                break;
            case 'forgotPasswordSubmit':
                logger.debug('Hub: Password confirmation successful');
                break;
            case 'forgotPasswordSubmit_failure':
                logger.error('Hub: Password confirmation failed');
                break;
            case 'verify':
                logger.debug('Hub: TOTP token verification successful');
                break;
            case 'cognitoHostedUI':
                logger.debug('Hub: Cognito Hosted UI sign in successful');
                postAuthenticationTasks();
                break;
            case 'cognitoHostedUI_failure':
                logger.error('Hub: Cognito Hosted UI sign in failed');
                break;
            case 'customState_failure':
                logger.error('Hub: Custom state failure');
                break;
            case 'parsingCallbackUrl':
                logger.debug('Hub: Cognito Hosted UI oAuth url parsing initiated');
                break;
            case 'userDeleted':
                logger.debug('Hub: User deleted');
                break;
            case 'updateUserAttributes':
                logger.debug('Hub: User attributes updated');
                break;
            case 'updateUserAttributes_failure':
                logger.debug('Hub: User attributes update failed');
                break;

            // Amplify v6 hub cases
            case 'signInWithRedirect':
                logger.debug("Hub: Signed in with redirect");
                break;
            case 'signInWithRedirect_failure':
                logger.debug("Hub: Failed to sign in with redirect");
                break;
            case 'tokenRefresh':
                logger.debug('Hub: User token refresh succeeded');
                postAuthenticationTasks();
                break;
            case 'tokenRefresh_failure':
                logger.error('Hub: User token refresh failed');
                break;
            case 'customOAuthState':
                logger.debug('Hub: Custom state returned from Cognito Hosted UI');
                logger.debug('Hub: Hash state: ' + data)
                storeHashState(atob(data));
                break;
            case 'signedOut':
                logger.debug('Hub: User signed out');
                break;
            case 'signedIn':
                logger.debug('Hub: User signed in');
                postAuthenticationTasks();
                break;
            default:
                break;
        }
    });
    logger.debug(`Hub: Listener started`);
}

function storeHashState(hashState) {
    if (!hashState) {
        hashState = getHashState();
        logger.debug("storeHashState: Hash state found");
        logger.debug("storeHashState: Hash state is: " + hashState);
    }

    if (hashState) {
        removeHashState();
        storedHashState = hashState;
        logger.debug("storeHashState: Hash state stored and removed from URL bar");
    }
}

async function getSession() {
    try {
        let tokens = (await fetchAuthSession())?.tokens;
        let idToken = tokens.idToken.toString();
        logger.warn('getSession: Current session available');
        return idToken
    } catch (error) {
        logger.warn('getSession: Existing session not available');
        return false;
    }
}

async function initiateSession(setShouldSignIn) {
    logger.debug('Initiate Session: Starting');
    storeHashState();

    // Check for an existing session
    if (await getSession()) {
        postAuthenticationTasks();
        return;
    }

    // Check for a domain hint and start a new session with the idP
    if (getDomainHintEmail()) {
        session = await authenticateAgainstIdp();
    }

    // Fallback, show the UI, and remove old sessions
    logger.debug('Initiate Session: Showing login screen');
    signOutCustom(false, true);
    setShouldSignIn(true);
    logger.debug('Initiate Session: Finished');
}

function getAmplifyConfiguration(userIdP) {
    logger.debug("Amplify: Generating configuration");
    logger.debug(`Amplify: Provided user configuration: ${JSON.stringify(userIdP)}`);

    // User has an idP 
    if (userIdP?.appId && userIdP?.identityProvider) {
        logger.debug(`Amplify: Generating idP configuration`);
        return {
            Auth: {
                Cognito: {
                    //aws_cognito_region: COGNITO_REGION,
                    userPoolId: getCognitoUserPool(),
                    userPoolClientId: userIdP.appId,
                    loginWith: {
                        // OPTIONAL - Hosted UI configuration
                        oauth: {
                            domain: getCognitoEndpoint(),
                            scopes: Constants.OAUTH_SCOPES,
                            redirectSignIn: getCognitoRedirectSignInEndpoint(),
                            redirectSignOut: getCognitoRedirectSignOutEndpoint(),
                            clientId: userIdP.appId,
                            responseType: 'code'
                        }
                    }
                }
            }
        }        
    } 
    
    // User does not have an idP
    if (userIdP?.appId) {
        logger.debug(`Amplify: Generating non-idP configuration for existing user`);
        return {
            Auth: {
                Cognito: {
                    //aws_cognito_region: COGNITO_REGION,
                    userPoolId: getCognitoUserPool(),
                    userPoolClientId: userIdP.appId
                }
            }
        }
    }
    
    // User has nothing
    logger.debug(`Amplify: Generating non-idP configuration for new user`);
    return {
        Auth: {
            Cognito: {
                //aws_cognito_region: COGNITO_REGION,
                userPoolId: getCognitoUserPool(),
                userPoolClientId: getCognitoDefaultClient()
            }
        }
    }
}

async function authenticateAgainstIdp(userIdP) {
    logger.debug("Authentication: Starting against idP");
    var input;

    if (!userIdP) {
        userIdP = await initiateAmplify();
    } else {
        await initiateAmplify(userIdP);
    }

    if (!userIdP?.identityProvider) {
        logger.error("Authentication: Provided idP configuration invalid for idP flow");
        return false;  
    }

    try {
        if (storedHashState) {
            logger.debug("Authentication: Stored state found, sending state with idP request");
            input = {
                provider: {
                    custom: userIdP.identityProvider,
                },
                customState: btoa(storedHashState)
            }
        } else {
            logger.debug("Authentication: Stored state not found, skipping state with idP request");
            input = {
                provider: {
                    custom: userIdP.identityProvider
                } 
            }
        }
        await signInWithRedirect(input);
    } catch (error) {
        logger.error("Error during idP sign in");
        logger.error(error);
        return false;
    }
}

// Stub
async function handlePartialSignIn(nextStep) {
    logger.debug("Authentication: User is partially signed in and needs to complete a challenge");
    logger.error(nextStep)
    return nextStep.signInStep;
}

async function setUpMfa() {
    const totpSetupDetails = await setUpTOTP();
    const appName = 'ezyVet';
    const setupUri = totpSetupDetails.getSetupUri(appName);
    return setupUri;
}

async function updateUserPassword(oldPassword, newPassword) {
    try {
        await updatePassword({oldPassword, newPassword});
        logger.debug("Set New Password: User's password updated");
        return [true, null]
    } catch (error) {
        logger.error("Set New Password: Failed to change user's password");
        printCognitoError(error, logger)
        if (error?.name.includes("UserUnAuthenticatedException")) {
            return [false, Constants.NOT_AUTHENTICATED_ERROR]
        }

        if (error?.name.includes("InvalidParameterException")) {
            return [false, Constants.PASSWORD_NOT_COMPLEX]
        }

        if (error?.message.includes("User is not authorized to")) {
            return [false, Constants.IDP_USER_NOT_AUTHORISED]
        }

        if (error?.name.includes("NotAuthorizedException")) {
            return [false, Constants.PASSWORD_INCORRECT]
        }

        return [false, error?.name]
    }
}

async function handleConfirmResetPassword(username, confirmationCode, newPassword) {
    try {
        const output = await confirmResetPassword({ username, confirmationCode, newPassword });
        return [true, null];
    } catch (error) {
        logger.debug("confirmResetPassword: Could not reset the users password")
        printCognitoError(error, logger);

        if (error?.name.includes("EmptyConfirmResetPasswordConfirmationCode")) {
            return [false, Constants.RESET_CODE_EMPTY];
        }

        if (error?.name.includes("EmptyConfirmResetPasswordUsername")) {
            return [false, Constants.RESET_USERNAME_EMPTY];
        }

        if (error?.name.includes("EmptyConfirmResetPasswordNewPassword")) {
            return [false, Constants.PASSWORD_SET_MESSAGE];
        }

        if (error?.name.includes("InvalidParameterException")) {
            return [false, Constants.PASSWORD_NOT_COMPLEX]
        }

        if (error?.name.includes("CodeMismatchException")) {
            return [false, Constants.RESET_CODE_INVALID]
        }

        if (error?.name.includes("ExpiredCodeException")) {
            return [false, Constants.RESET_CODE_EXPIRED]
        }
        
        if (error?.message.includes("not long enough")) {
            return [false, Constants.PASSWORD_TOO_SHORT]
        }

        if (error?.message.includes("too long")) {
            return [false, Constants.PASSWORD_TOO_LONG]

        }

        if (error?.name.includes("LimitExceededException")) {
            return [false, Constants.SLOW_DOWN]
        }

        return [false, error?.name];
    }
}

async function forgotPassword(username) {
    try {
        const output = await resetPassword({ username });
        return [true, null];
    } catch (error) {
        logger.debug("resetPassword: Could not reset the users password")
        printCognitoError(error, logger);

        if (error?.name.includes("LimitExceededException")) {
            return [false, Constants.SLOW_DOWN]
        }
        
        return [false, Constants.PASSWORD_CANNOT_RESET];
    }
}

async function getIsFederatedUser() {
    try {
        const userAttributes = await fetchUserAttributes();
        const isFederated = userAttributes?.identities?.includes("SAML");
        logger.debug("getIsFederatedUser: " + isFederated);
        return isFederated
    } catch (error) {
        logger.error("getIsFederatedUser: Failed to determine if user is federated");
        printCognitoError(error, logger);
        return false;
    }
}

async function getEmailAddress() {
    try {
        const userAttributes = await fetchUserAttributes();
        logger.error(userAttributes)
        logger.debug("getEmailAddress: Fetched user's email address: " + userAttributes.email);
        return userAttributes.email;
    } catch (error) {
        logger.error("getEmailAddress: Failed to get user's email address");
        printCognitoError(error, logger);
        return false;
    }
}

async function updateEmailAddress(newEmailAddress) {
    console.log(newEmailAddress)
    try {
        const output = await updateUserAttribute({
            userAttribute: {
                attributeKey: "email",
                value: newEmailAddress
            }
        });

        if (output.nextStep.updateAttributeStep == "CONFIRM_ATTRIBUTE_WITH_CODE") {
            logger.debug("updateEmailAddress: Confirmation code sent to: " + newEmailAddress);
            return [true, Constants.CHANGE_EMAIL_ADDRESS_CODE]
        }

        return [true, null];
    } catch (error) {
        logger.error("updateEmailAddress: Failed to update the user's email address");
        printCognitoError(error, logger);

        if (error?.name.includes("InvalidParameterException")) {
            return [false, Constants.CHANGE_EMAIL_ADDRESS_INVALID]
        }

        if (error?.name.includes("UserNotFoundException")) {
            return [false, Constants.NOT_AUTHENTICATED_ERROR]
        }

        return [false, error?.name];
    }
}

async function confirmAttributeUpdate(emailCode) {
    try {
        await confirmUserAttribute({ 
            userAttributeKey: "email",
            confirmationCode: emailCode
        });
        logger.debug("confirmAttributeUpdate: User email updated");
        return [true, null]
    } catch (error) {
        printCognitoError(error, logger);
        if (error?.name.includes("EmptyConfirmUserAttributeCode")) {
            return [false, Constants.CHANGE_EMAIL_ADDRESS_CODE_EMPTY]
        }

        if (error?.name.includes("CodeMismatchException")) {
            return [false, Constants.CHANGE_EMAIL_ADDRESS_CODE_WRONG]
        }

        if (error?.name.includes("AliasExistsException")) {
            return [false, Constants.CHANGE_EMAIL_ADDRESS_IN_USE]
        }

        return [false, error?.name];
    }
}

async function isSignedIn() {
    try {
        await getCurrentUser();
        logger.debug("isSignedIn: User is signed in");
        return true;
    } catch (error) {
        logger.error("isSignedIn: User is not signed in");
        printCognitoError(error, logger);
        return false;
    }
}

async function getMfaPreference() {
    try {
        const { enabled, preferred } = await fetchMFAPreference();
        let isEnabled = false;
        if (enabled?.includes("TOTP")) {
            isEnabled = true;
        } else {
            isEnabled = false;
        }
        logger.debug("Get MFA Preference: MFA is: " + isEnabled)
        return isEnabled;
    } catch (error) {
        logger.error("Get MFA Preference: Failed to get user's MFA preference");
        printCognitoError(error, logger);
        return false;
    }
}

async function toggleMfaPreference(shouldEnable) {
    try {
        if (shouldEnable) {
            await updateMFAPreference({totp: 'ENABLED' });
        } else {
            await updateMFAPreference({totp: 'DISABLED' });
        }
        logger.warn("toggleMfaPreference: MFA set to: " + shouldEnable);
        return [true, null];
    } catch (error) {
        logger.error("toggleMfaPreference: Failed to set MFA preference to: " + shouldEnable);
        printCognitoError(error, logger);
        if (error?.name.includes("InvalidParameterException")) {
            return [false, Constants.IDP_USER_NOT_AUTHORISED]
        }
        return [false, error?.name];
    }
}

async function verifyMfaSetup(mfaSetupCode) {
    try {
        await verifyTOTPSetup({ code: mfaSetupCode });
        await toggleMfaPreference(true);
        logger.debug("verifyMfaSetup: MFA setup complete");
        return [true, null]
    } catch (error) {
        printCognitoError(error, logger);
        if (error?.message.includes("code is required")) {
            return [false, Constants.MFA_EMPTY_CODE]
        }

        if (error?.message.includes("length greater")) {
            return [false, Constants.MFA_TOO_SHORT]
        }

        if (error?.message.includes("length less")) {
            return [false, Constants.MFA_TOO_LONG]
        }

        if (error?.message.includes("Code mismatch")) {
            return [false, Constants.MFA_WRONG]
        }

        return [false, error?.name];
    }
}

async function completeChallange(challangeAnswer) {
    try {
        logger.error(challangeAnswer)
        await confirmSignIn({
            challengeResponse: challangeAnswer,
        });
        return [true, null];
    } catch (error) {
        printCognitoError(error, logger);
        if (error?.message.includes("Invalid code received")) {
            return [false, Constants.MFA_WRONG]
        }
                    
        if (error?.message.includes("satisfy regular")) {
            return [false, Constants.PASSWORD_NOT_COMPLEX]
        }

        if (error?.message.includes("not long enough")) {
            return [false, Constants.PASSWORD_TOO_SHORT]
        }

        if (error?.message.includes("too long")) {
            return [false, Constants.PASSWORD_TOO_LONG]
        }

        if (error?.message.includes("session is expired")) {
            return [false, Constants.PASSWORD_TOO_LONG]
        }
        return [false, error?.name];
    }
}

async function authenticateAgainstPool(userEmail, userPassword, userIdP, setSignInMode) {
    logger.debug("Authentication: Starting against pool");
    try {
        await initiateAmplify(userIdP);
        let { isSignedIn, nextStep } = await signIn({username: userEmail, password: userPassword});

        // User needs to set a new password, validate a MFA code, etc.
        if (!isSignedIn) {
            setSignInMode(await handlePartialSignIn(nextStep));
        }
        
        return [true, null];
    } catch (error) {
        printCognitoError(error, logger)

        if (error?.message.includes("already a signed")) {
            return [false, Constants.ALREADY_SIGNED_IN_ERROR];
        }

        return [false, Constants.USERNAME_PASSWORD_ERROR];
    }
}

async function postAuthenticationTasks(idToken = undefined) {
    logger.debug("Post Authentication Tasks: Started");

    if (window.WANTS_MFA) {
        logger.error("User requested MFA setup, pausing post-authentication tasks")
        return false;
    }

    if (!idToken) {
        idToken = await getSession();
    }

    if (!idToken) {
        return false;
    }

    var cookiesSet = setIdCookie(idToken);
    if (!cookiesSet) {
        return false;
    }

    // Override placeholder email cookie it exists
    var userEmail = getFromJwt(idToken, 'email');
    if (userEmail) {
        setEmailCookie(userEmail.toLowerCase());
    }

    postAuthenticationRedirect();
}

function checkUrlIsSafe(url) {
    // RegEx to match ezyvet.com domains, e.g. client.ezyvet.com, client.region.ezyvet.com, see readme for test cases.
    var regEx = new RegExp('^https:\\/(?:\\/(?:[^\\/][a-zA-Z0-9.]*[^-])\\.|\\/)ezyvet\\.com($|(?:[^.\\n])\\/?[^:\\n]*)$');

    // Check the requested redirect is a relative path or a ezyVet domain.
    if ((url && url.startsWith("/") && !url.startsWith("//")) ||
        (url && regEx.test(url))) {
        return true;
    } else {
        return false;
    }
}

function postAuthenticationRedirect() {
    logger.debug("Post Authentication Redirect: Started");

    
    if (isLocalHost() && !storedHashState) {
        logger.error("Post Authentication Redirect: Redirecting to development page");
        window.location.replace(Constants.EZYVET_DEV_DOMAIN);
        return;
    } 

    if (!storedHashState) {
        logger.error("Post Authentication Redirect: No state provided");
        window.location.replace(Constants.EZYVET_APP_DOMAIN);
        return;
    }

    var urlIsSafe = checkUrlIsSafe(storedHashState);
    if (!urlIsSafe) {
        logger.error("Post Authentication Redirect: Provided state is unsafe: " + storedHashState);
        window.location.replace(Constants.EZYVET_APP_DOMAIN);
        return 
    }

    logger.debug("Post Authentication Redirect: Redirecting to URL provided in state: " + storedHashState);    
    window.location.replace(storedHashState);
}

async function signOutCustom(redirectAfterSignout = true, retainEmailCookie = false) {
    logger.debug("Sign Out: Started");

    try {
        await signOut();
    } catch (error) {
        logger.error("Sign Out: Cognito sign-out failure");
        logger.error(error);
    }

    clearSsoCookies(retainEmailCookie);
    clearLocalStorage();

    if (redirectAfterSignout) {
        postSignOutRedirect();
    }

    logger.debug("Sign Out: Finished");
}

function postSignOutRedirect() {
    setTimeout(function(){
        window.location.replace("/");
    }, 2500);
}

async function getConfigurationForEmailAddress(email) {
    var result;
    var header = new Headers({
        'email': email,
        'Content-Type': 'application/json',
    });

    result = await callApi('GET', Constants.EZYVET_LOGIN_USER_IDP_PATH, header);
    if (!result) {
        return false;
    }
    
    return result; 
}  

function setClientIdCookie(clientId) {
    var domain = getCookieDomain();
    if (!clientId) {
        return false;
    }

    setCookie(`${Constants.EZYVET_LOGIN_COOKIE_PREFIX}_${getCookieModifier()}_${Constants.EZYVET_LOGIN_COOKIE_CLIENT}`, clientId, domain, 157680000);
    return true;
}

function setEmailCookie(userEmail) {
    var domain = getCookieDomain();
    if (!userEmail) {
        return false;
    }

    userEmail = window.btoa(userEmail);
    setCookie(`${Constants.EZYVET_LOGIN_COOKIE_PREFIX}_${getCookieModifier()}_${Constants.EZYVET_LOGIN_COOKIE_EMAIL}`, userEmail, domain, 157680000);
    return true;
}

function setIdCookie(idToken) { 
    var expires_in = getFromJwt(idToken, 'exp') - Math.floor(Date.now() / 1000);
    if (!expires_in) {
        return false;
    }

    var domain = getCookieDomain();
    setCookie(`${Constants.EZYVET_LOGIN_COOKIE_PREFIX}_${getCookieModifier()}_${Constants.EZYVET_LOGIN_COOKIE_ID}`, idToken, domain, expires_in);
    return true;
}

function clearSsoCookies(retainEmailCookie) {
    logger.debug("Removing ezyVet cookies");
    var domain = getCookieDomain();
    setCookie(`${Constants.EZYVET_LOGIN_COOKIE_PREFIX}_${getCookieModifier()}_${Constants.EZYVET_LOGIN_COOKIE_PROVIDER}`, "", domain, 0);
    setCookie(`${Constants.EZYVET_LOGIN_COOKIE_PREFIX}_${getCookieModifier()}_${Constants.EZYVET_LOGIN_COOKIE_CLIENT}`, "", domain, 0);
    setCookie(`${Constants.EZYVET_LOGIN_COOKIE_PREFIX}_${getCookieModifier()}_${Constants.EZYVET_LOGIN_COOKIE_ID}`, "", domain, 0);

    if (!retainEmailCookie) {
        setCookie(`${Constants.EZYVET_LOGIN_COOKIE_PREFIX}_${getCookieModifier()}_${Constants.EZYVET_LOGIN_COOKIE_EMAIL}`, "", domain, 0);
    }
}

function clearLocalStorage() {
    localStorage.clear();
}

async function getUserConfiguration(userEmail) {
    logger.debug("API: Getting user configuration");
    if (!userEmail) {
        return false;
    }

    var userIdP = await getConfigurationForEmailAddress(userEmail);
    if (!userIdP) {
        return false;
    }

    setEmailCookie(userEmail);
    setClientIdCookie(userIdP.appId);

    logger.debug(`API: User configuration: ${JSON.stringify(userIdP)}`);
    return userIdP;
}

export {
    postAuthenticationTasks,
    authenticateAgainstIdp,
    authenticateAgainstPool,
    postAuthenticationRedirect,
    handleConfirmResetPassword,
    confirmAttributeUpdate,
    toggleMfaPreference,
    getUserConfiguration,
    updateUserPassword,
    completeChallange,
    getEmailAddress,
    getIsFederatedUser,
    updateEmailAddress,
    getMfaPreference,
    initiateSession,
    verifyMfaSetup,
    getUserEmail,
    forgotPassword,
    signOutCustom,
    isSignedIn,
    setUpMfa
}