import ProfileImage from '../../images/profile0.png'; 
import CodeImage from '../../images/code.webp'; 
import Background from '../../images/vet.webp';
import * as Constants from '../../js/Constants';
import { ReactComponent as FingerPrint } from '../../images/svgs/fingerprint.svg';
import LoadingRipple from "../../components/LoadingRipple/LoadingRipple";

function Frame(props) {

    return (
        <div className="authenticate">
            {props.isLoading ? 
                <div className="authenticate__loading">
                    <LoadingRipple 
                        display={"dark"}>
                    </LoadingRipple>
                </div>
            :
                <>
                    {(props.title.toLowerCase().includes("code") || props.title.toLowerCase().includes("mfa")) && 
                        <div className="authenticate__image-container">
                            <img 
                                alt="Cat protecting data"
                                className="authenticate__image"
                                src={CodeImage}
                            />
                        </div>
                    }

                    {props.title === "Sign In!" && 
                        <div className="authenticate__image-container">
                            <img 
                                alt="Vets working with animals"
                                className="authenticate__image"
                                src={Background}
                            />
                        </div>
                    }
                
                    <div className="authenticate__text-container">
                        <div className="authenticate__title">
                            {props.title}
                        </div>

                        <div className="authenticate__description">
                            {props.description}
                        </div>                
                    </div>

                    {props.title === "Your Profile" &&
                        <div className="authenticate__image-container authenticate__image-container--profile">
                            <img 
                                alt="Your profile picture"
                                className="authenticate__profile-image"
                                src={ProfileImage}
                            />

                            <div className="authenticate__image-edit">
                                <FingerPrint></FingerPrint>
                            </div>
                        </div>
                    }

                    <div className="authenticate__input-container">
                        
                        { props.errorMessage &&
                            <div className="authenticate__error">
                                {props.errorMessage}
                            </div>
                        }

                        {props.children}

                        {props.title === "Sign In!" && 
                            <a href={Constants.SIGN_UP_MAIL_TO} className="authenticate__new">
                                New here? Request account
                            </a>
                        }
                    </div>
                </>
            }
        </div>
    )
}


export default Frame