import Input from '../Input/Input';
import Button from '../Button/Button';
import Label from '../Label/Label';
import Checkbox from '../Checkbox/Checkbox';

function ProfileOptions(props) {

    return (
        <>
            <Label title={"Change Email"}></Label>
            <Input
                placeholder={props.emailAddress || "New Email Address"}
                onChange={props.handleNewEmailAddressChange}
                onEnterPressed={props.handleSaveButton}
                isLocked={props.isFederated}
            />

            <Label title={"Change Password"}></Label>
            <Input
                placeholder="Old Password"
                type="password"
                onChange={props.handleOldPasswordChange}
                onEnterPressed={props.handleSaveButton}
                isLocked={props.isFederated}
            />

            <Input
                placeholder="New Password"
                type="password"
                onChange={props.handleNewPasswordChange}
                onEnterPressed={props.handleSaveButton}
                isLocked={props.isFederated}
            />

            <Input
                placeholder="Repeat New Password"
                type="password"
                onChange={props.handleRepeatNewPasswordChange}
                onEnterPressed={props.handleSaveButton}
                isLocked={props.isFederated}
            />

            <Checkbox
                onChange={props.handleEnableMfaChange}
                checked={props.enableMfa}
                isLoading={props.isLoading}
                label="Enable MFA"
                isLocked={props.isFederated}
            />  

            <Button
                onClick={props.handleSaveButton}
                isLoading={props.isLoading}
                label="Save Changes" 
            />
        </>
    )
}

export default ProfileOptions


