import { 
    DEV_COGNITO_SIGN_IN_URLS,
    DEV_COGNITO_SIGN_OUT_URLS,
    PROD_COGNITO_SIGN_IN_URLS,
    TRIAL_COGNITO_SIGN_IN_URLS,
    STAGING_COGNITO_SIGN_IN_URLS,
    PROD_COGNITO_SIGN_OUT_URLS,
    TRIAL_COGNITO_SIGN_OUT_URLS,
    STAGING_COGNITO_SIGN_OUT_URLS,
    PROD_COGNITO_POOL,
    TRIAL_COGNITO_POOL,
    STAGING_COGNITO_POOL,
    PROD_COGNITO_ENDPOINT,
    TRIAL_COGNITO_ENDPOINT,
    STAGING_COGNITO_ENDPOINT,
    PROD_EZYVET_LOGIN_URL,
    TRIAL_EZYVET_LOGIN_URL,
    STAGING_EZYVET_LOGIN_URL,
    PROD_EZYVET_LOGIN_COOKIE_ENV,
    TRIAL_EZYVET_LOGIN_COOKIE_ENV,
    STAGING_EZYVET_LOGIN_COOKIE_ENV,
    PROD_EZYVET_LOGIN_COOKIE_DOMAIN,
    TRIAL_EZYVET_LOGIN_COOKIE_DOMAIN,
    STAGING_EZYVET_LOGIN_COOKIE_DOMAIN,
    PROD_EZYVET_LOGIN_DEFAULT_CLIENT,
    TRIAL_EZYVET_LOGIN_DEFAULT_CLIENT,
    STAGING_EZYVET_LOGIN_DEFAULT_CLIENT
} from './Constants';

function getEnvironmentUrl(){
    if (isLocalHost()) {
        return `https://${PROD_EZYVET_LOGIN_URL}`;
    }

    return `https://${window.location.hostname}`;
}

function getCognitoRedirectSignOutEndpoint() {
    if (isLocalHost()) {
        return DEV_COGNITO_SIGN_OUT_URLS;
    }

    if (window.location.hostname === PROD_EZYVET_LOGIN_URL) {
        return PROD_COGNITO_SIGN_OUT_URLS;
    }

    if (window.location.hostname === TRIAL_EZYVET_LOGIN_URL) {
        return TRIAL_COGNITO_SIGN_OUT_URLS;
    }

    if (window.location.hostname === STAGING_EZYVET_LOGIN_URL) {
        return STAGING_COGNITO_SIGN_OUT_URLS;
    }
}

function getCognitoRedirectSignInEndpoint() {
    if (isLocalHost()) {
        return DEV_COGNITO_SIGN_IN_URLS;
    }

    if (window.location.hostname === PROD_EZYVET_LOGIN_URL) {
        return PROD_COGNITO_SIGN_IN_URLS;
    }

    if (window.location.hostname === TRIAL_EZYVET_LOGIN_URL) {
        return TRIAL_COGNITO_SIGN_IN_URLS;
    }

    if (window.location.hostname === STAGING_EZYVET_LOGIN_URL) {
        return STAGING_COGNITO_SIGN_IN_URLS;
    }
}

function getCognitoEndpoint() {
    if (isLocalHost()) {
        return PROD_COGNITO_ENDPOINT;
    }

    if (window.location.hostname === PROD_EZYVET_LOGIN_URL) {
        return PROD_COGNITO_ENDPOINT;
    }

    if (window.location.hostname === TRIAL_EZYVET_LOGIN_URL) {
        return TRIAL_COGNITO_ENDPOINT;
    }

    if (window.location.hostname === STAGING_EZYVET_LOGIN_URL) {
        return STAGING_COGNITO_ENDPOINT;
    }
}

function getEnvironmentName() {
    if (isLocalHost()) {
        return "development";
    }

    if (window.location.hostname === PROD_EZYVET_LOGIN_URL) {
        return "production";
    }

    if (window.location.hostname === TRIAL_EZYVET_LOGIN_URL) {
        return "trial";
    }

    if (window.location.hostname === STAGING_EZYVET_LOGIN_URL) {
        return "staging";
    }
}

function getCognitoUserPool() {
    if (isLocalHost()) {
        return PROD_COGNITO_POOL;
    }

    if (window.location.hostname === PROD_EZYVET_LOGIN_URL) {
        return PROD_COGNITO_POOL;
    }

    if (window.location.hostname === TRIAL_EZYVET_LOGIN_URL) {
        return TRIAL_COGNITO_POOL;
    }

    if (window.location.hostname === STAGING_EZYVET_LOGIN_URL) {
        return STAGING_COGNITO_POOL;
    }
}

function getCognitoDefaultClient() {
    if (isLocalHost()) {
        return PROD_EZYVET_LOGIN_DEFAULT_CLIENT;
    }

    if (window.location.hostname === PROD_EZYVET_LOGIN_URL) {
        return PROD_EZYVET_LOGIN_DEFAULT_CLIENT;
    }

    if (window.location.hostname === TRIAL_EZYVET_LOGIN_URL) {
        return TRIAL_EZYVET_LOGIN_DEFAULT_CLIENT;
    }

    if (window.location.hostname === STAGING_EZYVET_LOGIN_URL) {
        return STAGING_EZYVET_LOGIN_DEFAULT_CLIENT;
    }
}

function getCookieModifier() {
    if (isLocalHost()) {
        return PROD_EZYVET_LOGIN_COOKIE_ENV;
    }

    if (window.location.hostname === PROD_EZYVET_LOGIN_URL) {
        return PROD_EZYVET_LOGIN_COOKIE_ENV;
    }

    if (window.location.hostname === TRIAL_EZYVET_LOGIN_URL) {
        return TRIAL_EZYVET_LOGIN_COOKIE_ENV;
    }

    if (window.location.hostname === STAGING_EZYVET_LOGIN_URL) {
        return STAGING_EZYVET_LOGIN_COOKIE_ENV;
    }
}

function getCookieDomain(){
    if (isLocalHost()) {
        return null;
    }

    if (window.location.hostname === PROD_EZYVET_LOGIN_URL) {
        return PROD_EZYVET_LOGIN_COOKIE_DOMAIN;
    }

    if (window.location.hostname === TRIAL_EZYVET_LOGIN_URL) {
        return TRIAL_EZYVET_LOGIN_COOKIE_DOMAIN;
    }

    if (window.location.hostname === STAGING_EZYVET_LOGIN_URL) {
        return STAGING_EZYVET_LOGIN_COOKIE_DOMAIN;
    }
}

function getCookie(cookieName) {
    var b = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

function setCookie(cookieName, cookieValue, domain, maxAge) {
    if (domain) {
        document.cookie = `${cookieName}=${cookieValue}; domain=${domain}; max-age=${maxAge}; path=/; SameSite=Lax; Secure;`
    } else {
        document.cookie = `${cookieName}=${cookieValue}; max-age=${maxAge}; path=/; SameSite=Lax;`; 
    } 
}

function printCognitoError(error, logger) {
    logger.error(error?.message)
    logger.error(error?.recoverySuggestion)
    logger.error(error?.underlyingError)
    logger.error(error?.name)
}

function getFromJwt(token, field) {
    if (!token || !field) {
        return false;
    }

    try {
        // Split token parts
        var base64Token = token.split('.')[1];
        base64Token = base64Token.replace(/-/g, '+').replace(/_/g, '/');

        // Decode token part from base64
        var stringToken = atob(base64Token).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')
        var decodedToken = decodeURIComponent(stringToken);

        // Fetch key from token JSON
        var result = JSON.parse(decodedToken)[field];
        return result;
    } catch {
        return false;
    }
}

function getUriParameter(parameter) {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    return urlParams.get(parameter);
}

function getHashState() {
    try {
        var hash = window.location.hash.substr(1);
        var state = hash.split("state=")[1];
        return state;
    } catch (error) {
        return false;
    }
}

function removeHashState() {
    window.history.pushState("", document.title, window.location.pathname + window.location.search);
}

function copyMfaSetupCode(mfa_seed_raw) {
    var textField = document.createElement('textarea')
    textField.innerText = mfa_seed_raw;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    alert("Your MFA seed value has been copied to your clipboard. Paste it in your MFA app of choice.")
}

function isLocalHost() {
    return window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
}

export {
    getCognitoRedirectSignOutEndpoint,
    getCognitoRedirectSignInEndpoint,
    getCognitoDefaultClient,
    getEnvironmentName,
    getCookieModifier,
    getEnvironmentUrl,
    getCognitoUserPool,
    getCognitoEndpoint,
    printCognitoError,
    copyMfaSetupCode,
    removeHashState,
    getCookieDomain,
    getUriParameter,
    getHashState,
    isLocalHost,
    getCookie,
    setCookie,
    getFromJwt
}