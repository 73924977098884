import Input from '../Input/Input';
import Button from '../Button/Button';
import QRCodeSVG from 'qrcode.react';
import { copyMfaSetupCode } from '../../js/Helper';

function SetupMfa(props) {

    return (
        <>
            <div className="authenticate__qr">
                { props.mfaSeed ? 
                    <QRCodeSVG value={props.mfaSeed} />
                : null }
            </div>

            <div onClick={() => copyMfaSetupCode(props.mfaSeed)} 
                className="authenticate__copy-qr">
                Can't scan your code? 
            </div>

            <Input
                placeholder="MFA Code"
                onChange={props.handleMfaSetupCodeChange}
                onEnterPressed={props.handleSetupMfaButton}
            />

            <Button
                label="Confirm Code"
                onClick={props.handleSetupMfaButton}
                isLoading={props.isLoading}
            />
        </>  
    )
}


export default SetupMfa